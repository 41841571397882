import { useInfiniteQuery } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Segment } from "semantic-ui-react";
import { getWhatsappThreads } from "../api/whatsapp";
import PageLoader from "../components/PageLoader";

function Whatsapp() {
  const threadsQuery = useInfiniteQuery({
    queryKey: ["whatsapp-threads"],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await getWhatsappThreads({
        page: pageParam,
      });
      return res;
    },
    keepPreviousData: true,
    cacheTime: 60 * 60 * 1000,
  });

  if (threadsQuery.isLoading) return <PageLoader>Loading...</PageLoader>;

  if (threadsQuery.isError) {
    toast("Failed to fetch WhatsApp conversations", { type: "error" });
    return null;
  }

  if (threadsQuery?.data?.pages[0]?.data?.data?.length > 0) {
    return (
      <Navigate
        to={`/whatsapp/${threadsQuery?.data?.pages[0]?.data?.data[0].sqid}`}
      />
    );
  }

  return (
    <Container>
      <Segment basic style={{ height: "200px", textAlign: "center" }}>
        You do not have WhatsApp conversations to display
      </Segment>
    </Container>
  );
}

export default Whatsapp;
