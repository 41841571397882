import axios from "axios";
import config from "../config";

export function csrfCookie() {
  return axios.get(`${config.api_url}/sanctum/csrf-cookie`);
}

export function ipecsLogin(body) {
  return axios.post(`${config.api_url}/ipecs/login`, body, { timeout: 10000 });
}

export function getIpecsLoginOptions() {
  return axios.get(`${config.api_url}/ipecs/login`);
}

export function getMe() {
  return axios.get(`${config.api_url}/me`);
}

export function updateMe(body) {
  return axios.put(`${config.api_url}/me`, body);
}

export function logout() {
  return axios.post(
    `${config.api_url}/logout`,
    {},
    { headers: { Accept: "application/json" } },
  );
}

export function licenseKeyLookup(key) {
  return axios.get(`${config.api_url}/license-key/lookup/${key}`);
}

export function loginClockify(body) {
  return axios.post(`${config.api_url}/login/clockify`, body);
}

export function loginDynamics365(body) {
  return axios.post(`${config.api_url}/dynamics365/config`, body);
}

export function disconnectService(service) {
  return axios.post(`${config.api_url}/disconnect/${service}`);
}

export function ablyToken() {
  return axios.get(`${config.api_url}/ably/token`);
}
