import { useQuery } from "@tanstack/react-query";
import { getCallOptions } from "../api/calls";
import { useAuth } from "../contexts/AuthContext";
import { useCallData } from "../contexts/CallDataContext";
import { convertToE164 } from "../helpers/convertToE164";

function useCallOptions(number) {
  const { apiUser } = useAuth();

  const { currentCallMeta } = useCallData();

  const optionsQuery = useQuery({
    queryKey: ["call-options", [number]],
    queryFn: () => {
      const e164Number = convertToE164(number, apiUser?.phone_locality);
      return getCallOptions(e164Number);
    },
    enabled: true,
    keepPreviousData: true,
    cacheTime: 1000, // one hour
    staleTime: 1000, // one hour
  });

  const options = optionsQuery.data?.data ?? [];

  return { options, currentCallMeta };
}

export default useCallOptions;
