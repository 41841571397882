import { Dropdown, Form } from "semantic-ui-react";
import { useCallData } from "../contexts/CallDataContext";
import useCallOptions from "../hooks/CallOptions";

function CallOptionsForm({ number }) {
  const { options } = useCallOptions(number);
  const { onChange, currentCallMeta } = useCallData();

  if (!options) return null;

  return (
    <Form>
      {options.map(
        (o, i) =>
          o.type === "typeahead" &&
          o.options.length > 0 && (
            <Form.Field key={`form-field-${i}`}>
              <label>{o.label}</label>
              <Dropdown
                style={{ width: "clamp(200px, 80vw, 400px)" }}
                search
                fluid
                selection
                name={o.name}
                options={[{ key: "", value: "", text: "" }, ...o.options]}
                onChange={onChange}
                value={currentCallMeta[o.name] ?? ""}
              />
            </Form.Field>
          ),
      )}
    </Form>
  );
}

export default CallOptionsForm;
