import { toast } from "react-toastify";
import { Button } from "semantic-ui-react";

function CopyableText({ text }) {
  const copyToClipboard = async () => {
    try {
      await navigator?.clipboard?.writeText(text);
      toast("Copied to clipboard", { type: "success" });
    } catch (err) {
      toast("Failed to copy", { type: "error" });
    }
  };

  return (
    <>
      <span>{text} </span>
      <Button
        icon="copy"
        size="tiny"
        color="black"
        onClick={copyToClipboard}
        title="Copy to clipboard"
      />
    </>
  );
}

export default CopyableText;
