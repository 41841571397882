import { Button, Form, FormField, Input } from "semantic-ui-react";
import YesNoToggle from "./YesNoToggle";

function EngageDetails({
  uiConfigured,
  engage,
  onChange,
  onSubmit,
  onClickBack,
  submitting,
}) {
  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Field>
          <label>Is this an Engage order?</label>
          <YesNoToggle value={engage.required} onChange={onChange} />
        </Form.Field>
        {engage.required && (
          <>
            <FormField>
              <label>How many Engage licenses are required?</label>
              <p>Engage licences are provided on a per website basis</p>
              <Input
                type="number"
                name="quantity"
                onChange={onChange}
                value={engage.quantity}
              />
            </FormField>

            {!uiConfigured && (
              <>
                <Form.Field>
                  <label>Please enter tenant prefix</label>
                  <Input
                    name="tenant_prefix"
                    onChange={onChange}
                    value={engage.tenant_prefix}
                    fluid
                  />
                </Form.Field>

                <FormField>
                  <label>iPECS One Server URL</label>
                  <Input
                    name="ipecs_server_url"
                    onChange={onChange}
                    value={engage.ipecs_server_url}
                  />
                </FormField>
                <FormField>
                  <label>iPECS One API Key</label>
                  <Input
                    name="ipecs_api_key"
                    onChange={onChange}
                    value={engage.ipecs_api_key}
                  />
                </FormField>
                <FormField>
                  <label>IPECS One API Key Secret</label>
                  <Input
                    type="password"
                    autocomplete="new-password"
                    name="ipecs_api_key_secret"
                    onChange={onChange}
                    value={engage.ipecs_api_key_secret}
                  />
                </FormField>
              </>
            )}

            <FormField>
              <label>Domain of website</label>
              <Input name="domain" onChange={onChange} value={engage.domain} />
            </FormField>

            <FormField>
              <label>Admin email address</label>
              <p>
                Please enter the email address for whoever will be styling and
                installing the engage widget on the customers site. This may be
                the end users admin or a reseller admin.
              </p>

              <Input
                name="admin_email"
                onChange={onChange}
                value={engage.admin_email}
              />
            </FormField>
          </>
        )}

        <Button.Group>
          <Button onClick={onClickBack}>Back</Button>
          <Button.Or />
          <Button loading={submitting} primary>
            Next
          </Button>
        </Button.Group>
      </Form>
    </>
  );
}

export default EngageDetails;
