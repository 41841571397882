import React, { useContext, useState } from "react";

const CallDataContext = React.createContext();
const CallDataProvider = ({ children }) => {
  const [currentCallMeta, setCurrentCallMeta] = useState({});

  const onChange = (e, f) => {
    const { name, value } = f;

    setCurrentCallMeta((v) => ({ ...v, [name]: value }));
  };

  const defaultContext = {
    currentCallMeta,
    onChange,
  };

  return (
    <CallDataContext.Provider value={defaultContext}>
      {children}
    </CallDataContext.Provider>
  );
};

function useCallData() {
  return useContext(CallDataContext);
}

export { CallDataProvider, useCallData };
