import { sdk } from "@gc/ipecs-web-sdk";
import {
  useInfiniteQuery,
  useQueryClient,
  useQuery,
} from "@tanstack/react-query";
import * as Ably from "ably";
import { AblyProvider, useChannel } from "ably/react";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  matchPath,
  useLocation,
} from "react-router-dom";
import { Icon, Label, Menu, Segment } from "semantic-ui-react";
import styled from "styled-components";
import { ablyToken } from "./api/auth";
import { getEngageChats } from "./api/engage";
import { getWhatsappThreads } from "./api/whatsapp";
import PWA from "./components/PWA";
import config from "./config";
import { useAuth } from "./contexts/AuthContext";
import { CallLogsProvider, useCallLogs } from "./contexts/CallLogsContext";
import { CallsProvider } from "./contexts/CallsContext";
import { ChatProvider, useChat } from "./contexts/ChatContext";
import { DevicesProvider, useDevices } from "./contexts/DevicesContext";
import { MyPresenceProvider } from "./contexts/MyPresenceContext";
import { PhoneControlProvider } from "./contexts/PhoneControlContext";
import { PresenceProvider } from "./contexts/PresenceContext";
import { SoftphoneProvider } from "./contexts/SoftphoneContext";
import { TopbarProvider } from "./contexts/TopbarContext";
import engageLogo from "./images/engage-logo.png";
import sidekickLogo from "./images/sidekick-logo-75x79.png";
import layout from "./layout";
import devices from "./layout/devices";
import Apps from "./screens/Apps";
import Calendar from "./screens/Calendar";
import Calls from "./screens/Calls";
import Chat from "./screens/Chat";
import ChatRoom from "./screens/ChatRoom";
import Clockify from "./screens/Clockify";
import ConfigChecker from "./screens/ConfigChecker";
import Contacts from "./screens/Contacts";
import Engage from "./screens/Engage";
import EngageChat from "./screens/EngageChat";
import Insights from "./screens/Insights";
import IpecsLogin from "./screens/IpecsLogin";
import Login from "./screens/Login";
import MakeCall from "./screens/MakeCall";
import NotFound from "./screens/NotFound";
import Projects from "./screens/Projects";
import Settings from "./screens/Settings";
import Sidekick from "./screens/Sidekick";
import SimpleMakeCall from "./screens/SimpleMakeCall";
import WhatsNew from "./screens/WhatsNew";
import Whatsapp from "./screens/Whatsapp";
import WhatsappThread from "./screens/WhatsappThread";
import { UserClisProvider } from "./contexts/UserClisContext";
import Setup from "./screens/Setup";
import ApproveOrder from "./screens/ApproveOrder";
import { CallDataProvider } from "./contexts/CallDataContext";

const Sidebar = styled.div`
  overflow: hidden;
  min-width: ${layout.sidebarWidth};
  transition: all 100ms ease-in-out;
`;

const SidebarToggleWrapper = styled.div`
  display: block;
  position: absolute;
  left: 40px;
  top: 49px;
  zindex: 9999;
  cursor: pointer;
  color: #58b9b7;

  @media ${devices.tablet} {
    display: none;
  }
`;

const style = {
  wrapper: {
    minHeight: "100%",
  },
  app: {
    display: "flex",
  },
  sidebar: {
    height: `calc(100vh - ${layout.topbarHeight})`,
    overflowY: "auto",
    borderLeft: "none",
    borderTop: "none",
    borderBottom: "none",
    borderRadius: 0,
    minWidth: layout.sidebarWidth,
  },
  content: {
    width: "100%",
    height: `calc(100vh - ${layout.topbarHeight}`,
    overflowY: "scroll",
  },
};

const AppRoutes = () => {
  const { apiUser, ipecsUser, hasFeature, hasIntegration } = useAuth();

  const unauthenticatedRoutes = () => {
    return (
      <>
        <Route path="/" element={<Login />} />
        <Route path="/config-checker" element={<ConfigChecker />} />
        <Route path="*" element={<Navigate to="/" />} />
      </>
    );
  };

  const authenticatedWithoutIpecsRoutes = () => {
    return (
      <>
        <Route path="/" element={<IpecsLogin />} />
        <Route path="*" element={<IpecsLogin />} />
      </>
    );
  };

  const basicAuthenticatedRoutes = () => {
    return (
      <Route path="/" element={<BasicAuthLayout />}>
        <Route path="/simple-call/:number" element={<SimpleMakeCall />} />
      </Route>
    );
  };

  const authenticatedRoutes = () => {
    return (
      <Route path="/" element={<AuthLayout />}>
        <Route path="/pwa" element={<PWA />} />

        <Route index element={<Contacts />} />
        <Route path="/make-call/:number" element={<MakeCall />} />

        <Route
          path="/login/vincere/callback"
          Component={() => {
            window.location.href =
              config.api_url +
              "/login/vincere/callback" +
              window.location.search;
          }}
        />

        {hasFeature(["chat"]) && (
          <>
            <Route path="/chat" element={<Chat />} />
            <Route path="/chat/:id" element={<ChatRoom />} />
          </>
        )}
        <Route path="/calls" element={<Calls />} />
        {hasIntegration(["microsoft", "google"]) && (
          <Route path="/calendar" element={<Calendar />} />
        )}
        {hasIntegration(["asana", "clickup"]) && (
          <Route path="/projects" element={<Projects />} />
        )}
        {hasIntegration(["clockify"]) && (
          <Route path="/clockify" element={<Clockify />} />
        )}
        {hasFeature(["insights"]) && (
          <Route path="/insights" element={<Insights />} />
        )}
        <Route path="/engage" element={<Engage />} />
        <Route path="/engage/:id" element={<EngageChat />} />
        {apiUser?.has_sidekick && apiUser?.sidekick_configured && (
          <Route path="/sidekick" element={<Sidekick />} />
        )}
        <Route path="/apps" element={<Apps />} />
        <Route path="/settings/:action?" element={<Settings />} />
        <Route path="/whats-new" element={<WhatsNew />} />
        <Route path="/whatsapp" element={<Whatsapp />} />
        <Route path="/whatsapp/:id" element={<WhatsappThread />} />

        <Route path="*" element={<NotFound />} />
      </Route>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Wrapper />}>
          {!apiUser && unauthenticatedRoutes()}
          {apiUser && !ipecsUser && authenticatedWithoutIpecsRoutes()}
          {apiUser && ipecsUser && authenticatedRoutes()}
          {apiUser && ipecsUser && basicAuthenticatedRoutes()}
          <Route
            path="/partner/:billingPartnerId/orders/new"
            element={<Setup />}
          />
          <Route path="/order-approved" element={<ApproveOrder />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

function Wrapper() {
  return (
    <div style={style.wrapper}>
      <Outlet />
    </div>
  );
}

function BasicAuthLayout() {
  const { isPhoneControl } = useAuth();

  return (
    <DevicesProvider>
      <CallLogsProvider>
        <CallDataProvider>
          <CallHandler>
            <CallsProvider phoneControlMode={isPhoneControl}>
              <div>
                <Outlet />
              </div>
            </CallsProvider>
          </CallHandler>
        </CallDataProvider>
      </CallLogsProvider>
    </DevicesProvider>
  );
}

function AuthLayout() {
  const { isPhoneControl, apiUser } = useAuth();
  const { user, setLogLevel } = sdk;

  useEffect(() => {
    if (Number.isInteger(apiUser.sdk_log_level)) {
      setLogLevel(apiUser.sdk_log_level);
    }
  }, [setLogLevel, apiUser]);

  useQuery({
    queryKey: ["auth-check"],
    queryFn: () => user.getMyInfo(),
    refetchOnWindowFocus: true,
    refetchInterval: 60000,
    cacheTime: 20000,
    staleTime: 20000,
    onError: () => window.location.reload(),
  });

  return (
    <AblyWrapper>
      <MyPresenceProvider>
        <PresenceProvider>
          <DevicesProvider>
            <CallLogsProvider>
              <UserClisProvider>
                <CallDataProvider>
                  <CallHandler>
                    <CallsProvider phoneControlMode={isPhoneControl}>
                      <ChatProvider>
                        <AuthContent />
                      </ChatProvider>
                    </CallsProvider>
                  </CallHandler>
                </CallDataProvider>
              </UserClisProvider>
            </CallLogsProvider>
          </DevicesProvider>
        </PresenceProvider>
      </MyPresenceProvider>
    </AblyWrapper>
  );
}

function AblyWrapper({ children }) {
  const { apiUser } = useAuth();

  if (!apiUser.has_ably) return children;

  return (
    <AblyProvider
      client={Ably.Realtime.Promise({
        authCallback: async (data, callback) => {
          try {
            const tokenRequest = await ablyToken();
            callback(null, tokenRequest.data);
          } catch (e) {
            callback(e, null);
          }
        },
      })}
    >
      {children}
    </AblyProvider>
  );
}

function CallHandler({ children }) {
  const { isPhoneControl } = useAuth();

  if (isPhoneControl) {
    return <PhoneControlProvider>{children}</PhoneControlProvider>;
  } else {
    return <SoftphoneProvider>{children}</SoftphoneProvider>;
  }
}

function AuthContent() {
  const { hasFeature, hasIntegration, apiUser, isPhoneControl } = useAuth();
  const { getTotalUnread } = useChat();
  const { missedCallCount } = useCallLogs();
  const { audioAllowed } = useDevices();
  const [showSidebar, setShowSidebar] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setShowSidebar(
        window.innerWidth && window.innerWidth <= 540 ? false : true,
      );
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <TopbarProvider>
      <div style={style.app}>
        <SidebarToggleWrapper>
          <Icon
            name={showSidebar ? "chevron circle left" : "chevron circle right"}
            onClick={() => setShowSidebar(!showSidebar)}
          />
        </SidebarToggleWrapper>

        <Sidebar style={{ marginLeft: showSidebar ? 0 : "-100px" }}>
          <Menu
            inverted
            borderless
            icon="labeled"
            compact
            vertical
            style={style.sidebar}
          >
            <div style={{ flexGrow: "1" }}>
              <MenuItem to="/">
                <Icon name="address card" />
                Contacts
              </MenuItem>
              {hasFeature(["chat"]) && (
                <MenuItem to="/chat">
                  {getTotalUnread() > 0 && (
                    <AlertBadge count={getTotalUnread()} />
                  )}
                  <Icon name="chat" />
                  Chat
                </MenuItem>
              )}
              {apiUser.hasOwnProperty("engage_id") && <EngageMenuItem />}
              {hasFeature(["whatsapp"]) && <WhatsappMenuItem />}
              <MenuItem to="/calls">
                {missedCallCount > 0 && <AlertBadge count={missedCallCount} />}
                <Icon name="phone" />
                Calls
              </MenuItem>
              {hasIntegration(["microsoft", "google"]) && (
                <MenuItem to="/calendar">
                  <Icon name="calendar" />
                  Calendar
                </MenuItem>
              )}
              {hasIntegration(["asana", "clickup"]) && (
                <MenuItem to="/projects">
                  <Icon name="list" />
                  Projects
                </MenuItem>
              )}
              {hasIntegration(["clockify"]) && (
                <MenuItem to="/clockify">
                  <Icon name="clock" />
                  Clockify
                </MenuItem>
              )}
              {hasFeature(["insights"]) && (
                <MenuItem to="/insights">
                  <Icon name="chart line" />
                  Insights
                </MenuItem>
              )}
              {apiUser?.has_sidekick && apiUser?.sidekick_configured && (
                <MenuItem to="/sidekick">
                  <img
                    src={sidekickLogo}
                    alt="Sidekick logo"
                    align="center"
                    width="50px"
                    style={{ width: "25px", margin: "0px auto 6px auto" }}
                  />
                  Sidekick
                </MenuItem>
              )}
              <MenuItem to="/apps">
                <Icon name="clone" />
                Apps
              </MenuItem>
              <MenuItem to="/settings">
                <Icon name="settings" />
                Settings
              </MenuItem>
            </div>
          </Menu>
        </Sidebar>

        <div style={style.content}>
          {!isPhoneControl && !audioAllowed && (
            <Segment
              inverted
              color="red"
              style={{ borderRadius: 0, zIndex: 999 }}
            >
              Connect doesn't have permissions to audio devices. You will need
              to update your browser settings to allow Connect to use your audio
              devices.
            </Segment>
          )}

          <Outlet />
        </div>
      </div>
    </TopbarProvider>
  );
}

function AlertBadge({ count }) {
  return (
    <Label
      style={{
        position: "absolute",
        top: 8,
        right: 8,
        zIndex: 999,
      }}
      color="red"
    >
      {count}
    </Label>
  );
}

function EngageMenuItem() {
  const { apiUser } = useAuth();

  const queryClient = useQueryClient();

  const engageChatsQuery = useInfiniteQuery({
    queryKey: ["engage-chats"],
    queryFn: async ({ pageParam = 1 }) => {
      return await getEngageChats({ page: pageParam });
    },
    getNextPageParam: (lastPage) =>
      lastPage.data.meta.current_page < lastPage.data.meta.last_page
        ? lastPage.data.meta.current_page + 1
        : undefined,
    keepPreviousData: true,
    cacheTime: 60 * 60 * 1000,
  });

  useChannel(`chat:${apiUser.engage_id}:notifications`, () => {
    queryClient.invalidateQueries({
      queryKey: ["engage-chats"],
    });
  });

  return (
    <MenuItem to="/engage">
      {engageChatsQuery.data?.pages[0]?.data?.meta?.has_unread && (
        <Label
          style={{
            position: "absolute",
            top: 12,
            right: 30,
            zIndex: 999,
          }}
          color="red"
          circular
          empty
          size="large"
        />
      )}
      <img
        src={engageLogo}
        alt="Engage Logo"
        align="center"
        width="50px"
        style={{ width: "25px", margin: "0px auto 6px auto" }}
      />
      Engage
    </MenuItem>
  );
}

function WhatsappMenuItem() {
  const { apiUser } = useAuth();
  const { pathname } = useLocation();

  const queryClient = useQueryClient();

  const threadsQuery = useInfiniteQuery({
    queryKey: ["whatsapp-threads"],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await getWhatsappThreads({
        page: pageParam,
      });
      return res;
    },
    keepPreviousData: true,
    cacheTime: 60 * 60 * 1000,
  });

  useChannel(`whatsapp:${apiUser.customer_id}:notifications`, () => {
    queryClient.invalidateQueries({
      queryKey: ["whatsapp-threads"],
    });
  });

  return (
    <MenuItem to="/whatsapp">
      {threadsQuery?.data?.pages[0]?.data?.meta?.has_unread &&
        (`/whatsapp/*`, pathname) && (
          <Label
            style={{
              position: "absolute",
              top: 12,
              right: 30,
              zIndex: 999,
            }}
            color="red"
            circular
            empty
            size="large"
          />
        )}
      <Icon name="whatsapp" /> WhatsApp
    </MenuItem>
  );
}

function MenuItem({ to, children }) {
  const { pathname } = useLocation();

  return (
    <Menu.Item
      as={Link}
      to={to}
      active={matchPath(`${to}/*`, pathname) ? true : false}
    >
      {children}
    </Menu.Item>
  );
}

export default AppRoutes;
